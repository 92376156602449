<template>
  <div class="overview-card-list">
    <el-row :gutter="30">
      <el-col :span="6" v-for="(item, index) in dataList" :key="index">
        <div class="single-card" v-loading="item.loading">
          <div class="left-part">
            <img
              :src="
                require(`@/assets/images/rdata/overview_${item.type}_icon.png`)
              "
              alt=""
            />
          </div>
          <div class="right-part">
            <div class="label-line">
              {{ item.label }}
              <span
                v-if="item.yesterdayTotal"
                :style="{
                  color:
                    item.yesterdayTotal >= item.value ? '#67C23A' : '#ff0000',
                }"
                >{{ diffValueRender(item) }}</span
              >
            </div>
            <div class="value-line">
              <span class="value-span">
                {{ item.value }}
              </span>
              <span v-if="item.unit" class="unit-span">
                {{ item.unit }}
              </span>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  getPropertyTotal,
  getTodayAlarmTotal,
} from "@/api/ruge/bmsNew/dashboard.js";
import { getDashboardDeviceTotal } from "@/api/ruge/vlink/dashboard/index.js";
export default {
  name: "overviewCardList",
  data() {
    return {
      dataList: [
        {
          label: "接入设备总数",
          value: 0,
          type: "accessDevice",
          loading: false,
        },
        {
          label: "接入点位总数",
          value: 0,
          type: "accessPoint",
          loading: false,
        },
        {
          label: "在线设备总数",
          value: 0,
          type: "onlineDevice",
          loading: false,
        },
        {
          label: "当日告警总数",
          value: 0,
          yesterdayTotal: 0,
          type: "alarmCount",
          loading: false,
        },
      ],
    };
  },
  created() {
    this.initDatas();
  },
  methods: {
    diffValueRender({ value, yesterdayTotal }) {
      const diff = Math.abs(value - yesterdayTotal);
      const icon = value >= yesterdayTotal ? "↑" : "↓";
      const diffRate = ((diff / yesterdayTotal) * 100).toFixed(0);
      return `（${icon}${diffRate}%）`;
    },
    initDatas() {
      this.dataList.forEach((item) => {
        item.loading = true;
      });
      getPropertyTotal().then((res) => {
        this.setCountByKey("accessPoint", res);
      });
      getTodayAlarmTotal().then((res) => {
        this.setCountByKey("alarmCount", res.todayTotal, res.yesterdayTotal);
      });
      getDashboardDeviceTotal().then((res) => {
        const { deviceTotal, onlineTotal } = res;
        this.setCountByKey("accessDevice", deviceTotal);
        this.setCountByKey("onlineDevice", onlineTotal);
      });
    },
    setCountByKey(key, value, yesterdayTotal) {
      this.dataList.forEach((item) => {
        if (item.type === key) {
          item.value = value;
          item.loading = false;
          if (yesterdayTotal) {
            item.yesterdayTotal = yesterdayTotal;
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.overview-card-list {
  .single-card {
    height: 100px;
    background: #ffffff;
    border-radius: 20px;
    padding: 22px 18px;
    display: flex;
    .right-part {
      margin-left: 18px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .label-line {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #a3aed0;
        line-height: 14px;
      }
      .value-line {
        height: 28px;
        font-size: 28px;
        font-family: Helvetica-Bold, Helvetica;
        color: #152c5b;
        line-height: 28px;
        .value-span {
          font-weight: bold;
          display: inline-block;
        }
        .unit-span {
          font-weight: bold;
          font-size: 0.6em;
        }
      }
    }
  }
}
</style>