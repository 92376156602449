var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bms-dashboard app-main" },
    [
      _c("OverviewCardList"),
      _c(
        "div",
        { staticClass: "card-container" },
        [
          _c(
            "el-row",
            { staticClass: "detail-container", attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { staticClass: "detail-container", attrs: { span: 16 } },
                [_c("DeviceAlarmPast15Days")],
                1
              ),
              _c(
                "el-col",
                { staticClass: "detail-container", attrs: { span: 8 } },
                [_c("AlarmsForEachProject")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "card-container" },
        [
          _c(
            "el-row",
            { staticClass: "detail-container", attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { staticClass: "detail-container", attrs: { span: 8 } },
                [_c("ProportionOfWorkOrderTypes")],
                1
              ),
              _c(
                "el-col",
                { staticClass: "detail-container", attrs: { span: 16 } },
                [_c("WorkOrderCompletionTrend")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }