<template>
  <div class="order-types-proportion">
    <WaterEnergyTitle title="工单类型占比" />
    <el-tooltip
      :content="`更新时间：${dateFormat(updateTime, 'YYYY-MM-DD HH:mm:ss')}`"
      placement="top"
      effect="light"
    >
      <img
        class="update-time-icon"
        src="@/assets/images/update-time-icon.png"
        alt=""
      />
    </el-tooltip>
    <el-select
      v-model="projectId"
      placeholder="请选择"
      style="position: absolute; right: 20px; top: 14px; width: 130px"
      size="mini"
      @change="initDatas"
    >
      <el-option
        v-for="item in itemOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
    <div
      class="chart-container"
      id="orderTypesProportionChart"
      v-loading="listLoading"
    ></div>
  </div>
</template>

<script>
import WaterEnergyTitle from "@/components/WaterEnergyTitle";
import { mapGetters } from "vuex";
import * as echarts from "echarts";
import { getOrderTypeProportion } from "@/api/ruge/bmsNew/dashboard.js";
import { dateFormat } from "@/filters/index.js";
import { getProjectList } from "@/api/business/base/tenant/project";
export default {
  name: "order-types-proportion",
  components: { WaterEnergyTitle },
  data() {
    return {
      projectId: "",
      itemOptions: [],
      listLoading: false,
      myChart: null,
      updateTime: "",
    };
  },
  computed: {
    ...mapGetters(["sidebar"]),
  },
  watch: {
    ["sidebar.opened"]: {
      handler() {
        this.chartResize();
      },
      deep: true,
    },
  },
  mounted() {
    this.initDatas();
  },
  methods: {
    dateFormat,
    chartResize() {
      setTimeout(() => {
        this.myChart && this.myChart.resize();
      }, 500);
    },
    async initDatas() {
      this.listLoading = true;
      const projectItems = await getProjectList();
      this.itemOptions = [{ value: "", label: "全部" }].concat(
        projectItems.map((item) => {
          return {
            value: item.projectId,
            label: item.projectName,
          };
        })
      );
      console.log("this.itemOptions", this.itemOptions);
      getOrderTypeProportion({
        projectId: this.projectId,
      })
        .then((res) => {
          const { orderProportionList, lastUpdateDate } = res;
          this.updateTime = lastUpdateDate;
          let count = 0;
          orderProportionList.forEach((item) => {
            count += item.totalCount;
          });
          const seriesData = orderProportionList.map((item) => {
            const currentRate = ((item.totalCount / count) * 100).toFixed(0);
            return {
              value: item.totalCount,
              name: `${item.orderType}: ${currentRate}%`,
            };
          });
          this.initCharts(seriesData);
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    initCharts(seriesData) {
      const chartDom = document.getElementById("orderTypesProportionChart");
      this.myChart = echarts.init(chartDom);
      const option = {
        color: [
          "#1FF4E8",
          "#2AD9FF",
          "#2AA6FF",
          "#2A66FF",
          "#3A36FF",
          "#5930FF",
          "#7000FF",
          "#9441FF",
          "#B924FF",
          "#FF11CB",
          "#FFC32A",
          "#FF8383",
          "#E34848",
        ],
        // tooltip: {
        //   trigger: "item",
        //   padding: 10,
        //   borderWidth: 0,
        //   backgroundColor: "#00000099",
        //   textStyle: {
        //     color: "#ffffff",
        //   },
        //   formatter(params) {
        //     let domStr = "<div class='inspection-tips-main-g'>";
        //     domStr += `<span class="tips-circle-g" style="background: ${params.color}"></span><span>${params.name}</span>`;
        //     domStr += "</div>";
        //     return domStr;
        //   },
        // },
        tooltip: {
          show: true,
          padding: 0,
          borderWidth: 0,
          formatter(params) {
            return `<div class='alarm-tips-main'>${
              params.name.split(":")[0]
            }工单数量：${params.value}</div>`;
          },
        },
        series: [
          {
            type: "pie",
            roseType: "radius",
            radius: ["30%", "55%"],
            center: ["50%", "60%"],
            data: seriesData || [
              {
                value: 220,
                name: "平谷",
              },
              {
                value: 120,
                name: "低谷",
              },
              {
                value: 189,
                name: "高峰",
              },
              {
                value: 189,
                name: "尖峰",
              },
            ],
            label: {
              normal: {
                formatter: "{a|{b}}",
                rich: {
                  font: {
                    fontSize: 20,
                    padding: [5, 0],
                    color: "#fff",
                  },
                  hr: {
                    height: 0,
                    borderWidth: 1,
                    width: "100%",
                    borderColor: "#fff",
                  },
                },
              },
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0,0,0,0.5)",
              },
            },
          },
        ],
      };
      this.myChart.setOption(option, false);
    },
  },
};
</script>

<style lang="less" scoped>
.order-types-proportion {
  background: #ffffff;
  border-radius: 10px;
  height: 100%;
  padding: 14px 20px;
  position: relative;
  .update-time-icon {
    cursor: pointer;
    position: relative;
    top: 2px;
  }
  .update-time {
    margin-left: 10px;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #b5bece;
    line-height: 14px;
  }
  .chart-container {
    height: 230px;
    width: 100%;
  }
  ::v-deep .alarm-tips-main {
    background: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px 4px 4px 4px;
    height: 30px;
    line-height: 30px;
    padding: 0 15px;
    color: #000000;
  }
}
</style>