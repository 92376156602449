<template>
  <div class="bms-dashboard app-main">
    <OverviewCardList />
    <div class="card-container">
      <el-row :gutter="20" class="detail-container">
        <el-col :span="16" class="detail-container">
          <!-- 近15天设备告警趋势图 -->
          <DeviceAlarmPast15Days />
        </el-col>
        <el-col :span="8" class="detail-container">
          <!-- 近30天各项目告警数量占比 -->
          <AlarmsForEachProject />
        </el-col>
      </el-row>
    </div>
    <div class="card-container">
      <el-row :gutter="20" class="detail-container">
        <el-col :span="8" class="detail-container">
          <!-- 工单类型占比 -->
          <ProportionOfWorkOrderTypes />
        </el-col>
        <el-col :span="16" class="detail-container">
          <!-- 近15日工单生成量/完成量趋势图 -->
          <WorkOrderCompletionTrend />
        </el-col>
      </el-row>
    </div>
    <!-- <div class="card-container">
      <div class="title-line">招商</div>
      <el-row :gutter="20" class="detail-container">
        <el-col :span="16">
          <RentSituation />
        </el-col>
        <el-col :span="8">
          <ContractExpiration />
        </el-col>
      </el-row>
    </div> -->
  </div>
</template>

<script>
import ContractExpiration from "./components/contractExpirations.vue";
import RentSituation from "./components/rentSituations.vue";
import OverviewCardList from "./components/overviewCardLists.vue";
import DeviceAlarmPast15Days from "./components/deviceAlarmPast15Days.vue";
import AlarmsForEachProject from "./components/alarmsForEachProject.vue";
import ProportionOfWorkOrderTypes from "./components/proportionOfWorkOrderTypes.vue";
import WorkOrderCompletionTrend from "./components/workOrderCompletionTrend.vue";
export default {
  name: "bms-dashboard",
  components: {
    RentSituation,
    ContractExpiration,
    OverviewCardList,
    DeviceAlarmPast15Days,
    AlarmsForEachProject,
    ProportionOfWorkOrderTypes,
    WorkOrderCompletionTrend,
  },
};
</script>

<style lang="less" scoped>
.bms-dashboard {
  padding: 32px 16px 32px 40px;
  height: calc(100vh - 60px);
  overflow: auto;
  .title-line {
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 16px;
  }
  .card-container {
    height: 270px;
    margin-top: 30px;
  }
  .detail-container {
    height: 100%;
  }
}
</style>