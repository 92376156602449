<template>
  <div class="workorder-completion-trend">
    <WaterEnergyTitle title="近15日工单生成量/完成量趋势图" />
    <el-tooltip
      :content="`更新时间：${dateFormat(updateTime, 'YYYY-MM-DD HH:mm:ss')}`"
      placement="top"
      effect="light"
    >
      <img
        class="update-time-icon"
        src="@/assets/images/update-time-icon.png"
        alt=""
      />
    </el-tooltip>
    <el-select
      v-model="projectId"
      placeholder="请选择"
      style="position: absolute; right: 20px; top: 14px; width: 130px"
      size="mini"
      @change="initDatas"
    >
      <el-option
        v-for="item in itemOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
    <div
      class="chart-container"
      id="orderCompletionTrendChart"
      v-loading="listLoading"
    ></div>
  </div>
</template>

<script>
import WaterEnergyTitle from "@/components/WaterEnergyTitle";
import { mapGetters } from "vuex";
import * as echarts from "echarts";
import { getNear15OrderTrend } from "@/api/ruge/bmsNew/dashboard.js";
import { dateFormat } from "@/filters/index.js";
import { getProjectList } from "@/api/business/base/tenant/project";
export default {
  name: "workorder-completion-trend",
  components: { WaterEnergyTitle },
  data() {
    return {
      listLoading: false,
      myChart: null,
      updateTime: null,
      projectId: "",
      itemOptions: [],
    };
  },
  computed: {
    ...mapGetters(["sidebar"]),
  },
  watch: {
    ["sidebar.opened"]: {
      handler() {
        this.chartResize();
      },
      deep: true,
    },
  },
  mounted() {
    this.initDatas();
  },
  methods: {
    dateFormat,
    chartResize() {
      setTimeout(() => {
        this.myChart && this.myChart.resize();
      }, 500);
    },
    async initDatas() {
      this.listLoading = true;
      const projectItems = await getProjectList();
      this.itemOptions = [{ value: "", label: "全部" }].concat(
        projectItems.map((item) => {
          return {
            value: item.projectId,
            label: item.projectName,
          };
        })
      );
      getNear15OrderTrend({
        projectId: this.projectId,
      })
        .then((res) => {
          const { completedOrderList, createOrderList, lastUpdateDate } = res;
          this.updateTime = lastUpdateDate;
          let completeList = [];
          let createList = [];
          let dateList = [];
          completedOrderList.forEach((item, index) => {
            completeList.push(item.totalCount);
            createList.push(createOrderList[index].totalCount);
            dateList.push(item.recordDate.split("-").slice(1, 3).join("-"));
          });
          this.initCharts(completeList, createList, dateList);
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    initCharts(completeList, createList, dateList) {
      const chartDom = document.getElementById("orderCompletionTrendChart");
      this.myChart = echarts.init(chartDom);
      const option = {
        grid: {
          left: "40px",
          right: "20px",
          bottom: "30px",
          top: "30px",
        },
        tooltip: {
          show: true,
          trigger: "axis",
          padding: 0,
          borderWidth: 0,
          backgroundColor: "#ffffff00",
          axisPointer: {
            type: "line",
            lineStyle: {
              // width: 20,
              type: "dotted",
              color: "#2E8EFF",
              // opacity: 0.5,
              // color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
              //   {
              //     offset: 0,
              //     color: "#0089FF",
              //   },
              //   {
              //     offset: 1,
              //     color: "#ffffff00",
              //   },
              // ]),
            },
          },
          alwaysShowContent: false,
          formatter(params) {
            console.log("params", params);
            let domStr = `<div class='order-tips-main'>`;
            params.forEach((item) => {
              domStr += `<div class="detail-line">
                <span class="dot-span" style="background: ${item.color}"></span>
                ${item.seriesName}: ${item.value}
                </div>`;
            });
            domStr += "</div>";
            return domStr;
          },
        },
        xAxis: {
          boundaryGap: false,
          type: "category",
          data: dateList || ["9-1", "9-2", "9-3", "9-4", "9-5", "9-6", "9-7"],
          splitLine: {
            show: true,
            lineStyle: {
              color: "#0089FF20",
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              color: "#0089FF20",
            },
          },
        },
        series: [
          {
            name: "生成量",
            type: "line",
            smooth: true,
            color: "#2E73FF",
            lineStyle: {
              width: 2,
              color: "#0089FF",
            },
            showSymbol: false,
            label: {
              show: false,
            },
            areaStyle: {
              opacity: 1,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#2E73FF",
                },
                {
                  offset: 1,
                  color: "#ffffff00",
                },
              ]),
            },
            data: createList || [50, 140, 232, 101, 264, 90, 340],
          },
          {
            name: "完成量",
            type: "line",
            smooth: true,
            color: "#0FDFFC",
            lineStyle: {
              width: 2,
              color: "#0FDFFC",
            },
            showSymbol: false,
            label: {
              show: false,
            },
            areaStyle: {
              opacity: 1,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#0FDFFC",
                },
                {
                  offset: 1,
                  color: "#ffffff00",
                },
              ]),
            },
            data: completeList || [150, 140, 2, 33, 66, 190, 11],
          },
        ],
      };
      this.myChart.setOption(option, false);
    },
  },
};
</script>

<style lang="less" scoped>
.workorder-completion-trend {
  background: #ffffff;
  border-radius: 10px;
  height: 100%;
  padding: 14px 20px;
  position: relative;
  .update-time-icon {
    cursor: pointer;
    position: relative;
    top: 2px;
  }
  .update-time {
    margin-left: 10px;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #b5bece;
    line-height: 14px;
  }
  .chart-container {
    height: 230px;
    width: 100%;
  }
  ::v-deep .order-tips-main {
    background: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px 4px 4px 4px;
    line-height: 30px;
    padding: 0 15px;
    color: #000000;
    .detail-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .dot-span {
        height: 12px;
        width: 12px;
        border-radius: 12px;
        margin-right: 10px;
      }
    }
  }
}
</style>