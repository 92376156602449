<template>
  <div class="alarm-for-project">
    <WaterEnergyTitle title="近30天各项目告警数量占比" />
    <el-tooltip
      :content="`更新时间：${dateFormat(updateTime, 'YYYY-MM-DD HH:mm:ss')}`"
      placement="top"
      effect="light"
    >
      <img
        class="update-time-icon"
        src="@/assets/images/update-time-icon.png"
        alt=""
      />
    </el-tooltip>
    <div
      class="chart-container"
      id="projectAlarmsChartsDom"
      v-loading="listLoading"
    ></div>
  </div>
</template>

<script>
import WaterEnergyTitle from "@/components/WaterEnergyTitle";
import { mapGetters } from "vuex";
import * as echarts from "echarts";
import { getNear30AlarmProportion } from "@/api/ruge/bmsNew/dashboard.js";
import { dateFormat } from "@/filters/index.js";

export default {
  name: "alarm-for-project",
  components: { WaterEnergyTitle },
  data() {
    return {
      updateTime: new Date().getTime(),
      listLoading: false,
      myChart: null,
    };
  },
  computed: {
    ...mapGetters(["sidebar"]),
  },
  watch: {
    ["sidebar.opened"]: {
      handler() {
        this.chartResize();
      },
      deep: true,
    },
  },
  mounted() {
    this.initDatas();
  },
  methods: {
    dateFormat,
    chartResize() {
      setTimeout(() => {
        this.myChart && this.myChart.resize();
      }, 500);
    },
    initDatas() {
      this.listLoading = true;
      getNear30AlarmProportion()
        .then((res) => {
          const { alarmProportionList, lastUpdateDate } = res;
          this.updateTime = lastUpdateDate;
          let count = 0;
          alarmProportionList.forEach((item) => {
            count += item.totalCount;
          });
          const temp = alarmProportionList.map((item) => {
            const currentRate = ((item.totalCount / count) * 100).toFixed(0);
            return {
              value: item.totalCount,
              name: `${item.projectName}: ${currentRate}%`,
              currentRate,
            };
          });
          this.initCharts(temp);
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    initCharts(datas) {
      const chartDom = document.getElementById("projectAlarmsChartsDom");
      this.myChart = echarts.init(chartDom);
      const option = {
        tooltip: {
          trigger: "item",
        },
        color: ["#285AEB", "#2A8CFF", "#4EF2F2", "#FFD747"],
        // legend: {
        //   orient: "vertical",
        //   bottom: "bottom",
        // },
        tooltip: {
          show: true,
          padding: 0,
          borderWidth: 0,
          formatter(params) {
            return `<div class='alarm-tips-main'>${
              params.name.split(":")[0]
            }告警数量：${params.value}</div>`;
          },
        },
        series: [
          {
            type: "pie",
            radius: "50%",
            data: datas || [
              { value: 1048, name: "锦绣一期: 25%" },
              { value: 735, name: "锦绣二期: 25%" },
              { value: 580, name: "锦绣三期: 25%" },
              { value: 484, name: "其他: 25%" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      this.myChart.setOption(option, false);
    },
  },
};
</script>

<style lang="less" scoped>
.alarm-for-project {
  background: #ffffff;
  border-radius: 10px;
  height: 100%;
  padding: 14px 20px;
  .update-time-icon {
    cursor: pointer;
    position: relative;
    top: 2px;
  }
  .chart-container {
    height: 230px;
    width: 100%;
  }
  ::v-deep .alarm-tips-main {
    background: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px 4px 4px 4px;
    height: 30px;
    line-height: 30px;
    padding: 0 15px;
    color: #000000;
  }
}
</style>