var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "alarm-for-project" },
    [
      _c("WaterEnergyTitle", { attrs: { title: "近30天各项目告警数量占比" } }),
      _c(
        "el-tooltip",
        {
          attrs: {
            content: `更新时间：${_vm.dateFormat(
              _vm.updateTime,
              "YYYY-MM-DD HH:mm:ss"
            )}`,
            placement: "top",
            effect: "light",
          },
        },
        [
          _c("img", {
            staticClass: "update-time-icon",
            attrs: {
              src: require("@/assets/images/update-time-icon.png"),
              alt: "",
            },
          }),
        ]
      ),
      _c("div", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.listLoading,
            expression: "listLoading",
          },
        ],
        staticClass: "chart-container",
        attrs: { id: "projectAlarmsChartsDom" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }