<template>
  <div class="device-alarm-past15">
    <WaterEnergyTitle title="近15天设备告警趋势图" />
    <el-tooltip
      :content="`更新时间：${dateFormat(updateTime, 'YYYY-MM-DD HH:mm:ss')}`"
      placement="top"
      effect="light"
    >
      <img
        class="update-time-icon"
        src="@/assets/images/update-time-icon.png"
        alt=""
      />
    </el-tooltip>
    <el-select
      v-model="groupName"
      placeholder="请选择"
      style="position: absolute; right: 20px; top: 14px; width: 130px"
      size="mini"
      @change="initDatas"
    >
      <el-option
        v-for="item in itemOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
    <div
      class="chart-container"
      id="deviceAlarmPast15Dom"
      v-loading="listLoading"
    ></div>
  </div>
</template>

<script>
import WaterEnergyTitle from "@/components/WaterEnergyTitle";
import { mapGetters } from "vuex";
import * as echarts from "echarts";
import { getNear15AlarmTrend } from "@/api/ruge/bmsNew/dashboard.js";
import { dateFormat } from "@/filters/index.js";
export default {
  name: "device-alarm-past15",
  components: { WaterEnergyTitle },
  data() {
    return {
      updateTime: "",
      listLoading: false,
      myChart: null,
      groupName: "",
      itemOptions: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "锦绣一期",
          value: "锦绣一期",
        },
        {
          label: "锦绣二期",
          value: "锦绣二期",
        },
        {
          label: "锦绣三期",
          value: "锦绣三期",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["sidebar"]),
  },
  watch: {
    ["sidebar.opened"]: {
      handler() {
        this.chartResize();
      },
      deep: true,
    },
  },
  mounted() {
    this.initDatas();
  },
  methods: {
    dateFormat,
    chartResize() {
      setTimeout(() => {
        this.myChart && this.myChart.resize();
      }, 500);
    },
    initDatas() {
      this.listLoading = true;
      getNear15AlarmTrend({
        groupName: this.groupName,
      })
        .then((res) => {
          const { lastUpdateDate, alarmTrendList } = res;
          this.updateTime = lastUpdateDate;
          let dateList = [];
          let countList = [];
          alarmTrendList.forEach((item) => {
            dateList.push(item.recordDate.split("-").slice(1, 3).join("-"));
            countList.push(item.totalCount);
          });
          this.initCharts(dateList, countList);
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    initCharts(dateList, countList) {
      const chartDom = document.getElementById("deviceAlarmPast15Dom");
      this.myChart = echarts.init(chartDom);
      const option = {
        grid: {
          left: "40px",
          right: "20px",
          bottom: "30px",
          top: "30px",
        },
        tooltip: {
          show: true,
          trigger: "axis",
          padding: 0,
          borderWidth: 0,
          axisPointer: {
            type: "line",
            lineStyle: {
              // width: 20,
              type: "dotted",
              color: "#2E8EFF",
            },
          },
          alwaysShowContent: false,
          formatter(params) {
            return `<div class='alarm-tips-main'>告警数：${params[0].value}</div>`;
          },
        },
        xAxis: {
          boundaryGap: false,
          type: "category",
          data: dateList || ["9-1", "9-2", "9-3", "9-4", "9-5", "9-6", "9-7"],
          splitLine: {
            show: true,
            lineStyle: {
              color: "#0089FF20",
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              color: "#0089FF20",
            },
          },
        },
        series: [
          {
            name: "告警数",
            type: "line",
            smooth: true,
            color: "#0089FF",
            lineStyle: {
              width: 2,
              color: "#0089FF",
            },
            showSymbol: false,
            label: {
              show: false,
            },
            areaStyle: {
              opacity: 1,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#2E73FF",
                },
                {
                  offset: 1,
                  color: "#ffffff00",
                },
              ]),
            },
            data: countList || [50, 140, 232, 101, 264, 90, 340],
          },
        ],
      };
      this.myChart.setOption(option, false);
    },
  },
};
</script>

<style lang="less" scoped>
.device-alarm-past15 {
  background: #ffffff;
  border-radius: 10px;
  height: 100%;
  padding: 14px 20px;
  position: relative;
  .update-time-icon {
    cursor: pointer;
    position: relative;
    top: 2px;
  }
  .update-time {
    margin-left: 10px;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #b5bece;
    line-height: 14px;
  }
  .chart-container {
    height: 230px;
    width: 100%;
  }
  ::v-deep .alarm-tips-main {
    background: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px 4px 4px 4px;
    height: 30px;
    line-height: 30px;
    padding: 0 15px;
    color: #000000;
  }
}
</style>